export default {
  data: () => ({
    isFormError: false
  }),

  computed: {
    getFormErrorStates() {
      let errorConditions = this.getFormValidators

      return (
        Object.keys(errorConditions).filter(
          error => errorConditions[error] == true
        ) || []
      )
    }
  },

  methods: {
    goToFirstError() {
      // console.log(this.$refs)
      // console.log(this.getFormErrorStates)
      const firstError = this.getFormErrorStates[0]
      // console.log(this.$refs[firstError])
      this.$refs[firstError].focus()
    }
  },

  watch: {
    // isFormError(newVal) {
    //   // somehow focus the first errored input
    //   if (newVal) {
    //     console.log(this.$refs)
    //     const firstError = this.getFormErrorStates[0]
    //     console.log(this.$refs[firstError])
    //   }
    // },

    getFormErrorStates(newVal) {
      if (newVal.length == 0) this.isFormError = false
    }
  }
}
