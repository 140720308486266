// Date Helpers

// NB - deprecated all these date functions, partly since they extend native objects, and we should make use of date-fns. (Olly)
// date-fns format options: https://date-fns.org/v2.0.1/docs/format

import { isValid } from "date-fns/esm"
import { storeLocales } from "@/store/constants"

/**
 * Convert public_id in a Contentful/Cloudinary image object to a transformation-friendly format
 * @param {Object} imageObj - The image object to use
 */
export function cloudinaryPublicIdForTransformation(imageObj) {
  if (imageObj) {
    const regex = /\//g
    return imageObj.public_id.replace(regex, ":")
  }
}

export function localeToProperties(pathOrLocale) {
  const matchedLocale = Object.values(storeLocales).find(
    el => el.pathPrefix == pathOrLocale || el.locale == pathOrLocale
  )
  return matchedLocale
    ? matchedLocale
    : storeLocales[pathOrLocale]
    ? storeLocales[pathOrLocale]
    : storeLocales["uk"]
}

export function formatCurrency(object, multiplier = 1) {
  var price
  if (typeof object == "object") {
    price = object.cents / 100
  } else if (typeof object == "number") {
    price = object
  } else if (typeof object == "string") {
    price = parseFloat(object)
  } else {
    price = object
  }
  price = (price * parseInt(multiplier, 10)).toFixed(2)
  if (typeof object == "object") {
    // TODO: This should use a lookup table of all supported currencies and symbols
    if (object.currency == "USD") return `$${price}`
    else if (object.currency == "EUR") return `€${price}`
    else return `£${price}`
  } else {
    return `£${price}`
  }
}

export function navigateOnMap(lat, long) {
  // If it's an iPhone..
  if (
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1
  ) {
    var ver = [0]

    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later
      var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      ver = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
    }

    var protocol = "http://"
    if (ver[0] >= 6) {
      protocol = "maps://"
    }
    window.location =
      protocol + "maps.apple.com/maps?daddr=" + lat + "," + long + "&amp;ll="
  } else {
    window.open("http://maps.google.com?daddr=" + lat + "," + long + "&amp;ll=")
  }
}

export function camelToSnake(key) {
  return key
    .split("")
    .map((char, index) => {
      if (index !== 0 && char == char.toUpperCase()) {
        return "_" + char.toLowerCase()
      } else {
        return char
      }
    })
    .join("")
}

export function generatePassword(length = 8) {
  var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = ""
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

/**
 * Get the number of days in any particular month
 * @link https://stackoverflow.com/a/1433119/1293256
 * @param  {integer} m The month (valid: 0-11)
 * @param  {integer} y The year
 * @return {integer}   The number of days in the month
 */
var daysInMonth = function(m, y) {
  switch (m) {
    case 1:
      return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28
    case 8:
    case 3:
    case 5:
    case 10:
      return 30
    default:
      return 31
  }
}

/**
 * Check if a date is valid
 * @link https://stackoverflow.com/a/1433119/1293256
 * @param  {[type]}  d The day
 * @param  {[type]}  m The month
 * @param  {[type]}  y The year
 * @return {Boolean}   Returns true if valid
 */
export const isValidDate = function(d, m, y) {
  m = parseInt(m, 10) - 1
  return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y)
}

// Expects strings as parameters
export const validateDate = (day, month, year) => {
  if (day.length == 0 && month.length == 0 && year.length == 0) {
    return true
  }

  if (day.length == 0 || month.length == 0 || year.length == 0) {
    return false
  }

  if (inputDay < 1 || inputDay > 32) {
    return false
  } else if (inputMonth < 1 || inputMonth > 12) {
    return false
  } else if (inputYear < 1900 || inputYear > 2021) {
    return false
  }

  const inputDay = parseInt(day)
  const inputMonth = parseInt(month)
  const inputYear = parseInt(year)

  return (
    isValid(new Date(year, month - 1, day)) &&
    isValidDate(inputDay, inputMonth, inputYear)
  )
}

export function prefixedSlug(prefix = undefined, slug = undefined) {
  if (!slug || typeof slug !== "string") return undefined

  return `${prefix ? prefix + "/" : ""}${slug}`
}

export function validateEmail(email) {
  if (
    /* eslint-disable */
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  ) {
    return true
  }
  return false
}

export default {
  cloudinaryPublicIdForTransformation,
  formatCurrency,
  navigateOnMap,
  camelToSnake,
  generatePassword,
  isValidDate,
  localeToProperties,
  prefixedSlug,
  validateEmail
}
