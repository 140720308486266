import { mapGetters } from "vuex"

import { productTypes } from "../../../store/constants"

export default {
  computed: {
    ...mapGetters("content", ["GET_PRODUCT_ENTRY"]),

    getProductOrPageData() {
      return this.getProduct || this.product || this.pageData
    },

    getConstituentProducts() {
      return this.getProductOrPageData.products
        .map(product => {
          return this.GET_PRODUCT_ENTRY(product.sys.id, productTypes.PRODUCT)
        })
        .filter(product => product.numberOfReviews > 0)
    },

    getMergedProductReviewMetadata() {
      return this.getConstituentProducts
        .map(({ numberOfReviews, averageRating }) => ({
          averageRating,
          numberOfReviews
        }))
        .filter(product => product.numberOfReviews > 0)
        .reduce((acc, product, index) => {
          acc.averageRating = acc.averageRating || 0
          acc.averageRating =
            acc.averageRating +
            (product.averageRating - acc.averageRating) / (index + 1)
          acc.numberOfReviews =
            (acc.numberOfReviews || 0) + product.numberOfReviews
          return acc
        }, {})
    },

    getAverageRating() {
      if (this.getProductOrPageData.__typename == productTypes.SET) {
        return this.getMergedProductReviewMetadata.averageRating
      } else {
        return this.getProductOrPageData.averageRating
      }
    },

    getNumberOfReviews() {
      if (this.getProductOrPageData.__typename == productTypes.SET) {
        return this.getMergedProductReviewMetadata.numberOfReviews
      } else {
        return this.getProductOrPageData.numberOfReviews
      }
    },

    getHasReviews() {
      if (this.getProductOrPageData.__typename == productTypes.SET) {
        return this.getMergedProductReviewMetadata.numberOfReviews > 0
      } else {
        return this.getProductOrPageData.numberOfReviews > 0
      }
    }
  }
}
